import { getRewardCodeInput } from '@/queriesAndMutations/join';
import apiClient from '@/services/api-client';
import graphqlClient from '@/services/graphql-client';
import {
	Amperage,
	CreateElectricityQuoteMutation,
	PostalAreaFragment,
	QuoteRequestQuery,
} from '@/services/typed-graphql-sdk';
import {
	GridOperatorCode,
	gridOperatorCodeFromPostalArea,
} from '@/utils/constants/industry/gridOperator';
import {
	JourneyVariantByPath,
	SHOULD_DISPLAY_SIMPLE_ORGANIZATION_NAMES,
} from '@/utils/constants/marketing';
import { readSessionStorageOnce } from '@/utils/readSessionStorageOnce';

export const createAndGetQuote = async (input: {
	amperage?: Amperage;
	gridOperatorCodeFromSPIN?: GridOperatorCode;
	kva?: number;
	postalArea: PostalAreaFragment;
	productParams: ProductFilterParamType;
	usageAmount: number;
	usageMonth: number;
	useCache?: boolean;
}): Promise<QuoteRequestQuery['quoteRequest']> => {
	const affiliateSessionId =
		readSessionStorageOnce<string>('affiliateSessionId');
	const requestApi = input.useCache
		? '/api/onboarding/cached-create-quote'
		: '/api/onboarding/create-quote';
	const { createElectricityQuote } =
		await apiClient.post<CreateElectricityQuoteMutation>(requestApi, {
			input: {
				...(affiliateSessionId ? { affiliateSessionId } : {}),
				...(input.amperage ? { amperage: input.amperage } : {}),
				...(input.kva ? { kva: input.kva } : {}),
				gridOperatorCode:
					input.gridOperatorCodeFromSPIN ||
					gridOperatorCodeFromPostalArea(
						input.postalArea as PostalAreaFragment
					),
				params: getRewardCodeInput(),
				productParams: input.productParams,
				postalArea: input.postalArea,
				usageMonth: input.usageMonth,
				usageAmount: input.usageAmount,
			},
		});
	if (!createElectricityQuote?.quoteRequest?.code) {
		throw new Error('No quote returned from CreateElectricityQuoteMutation');
	}

	const { quoteRequest } = await graphqlClient.quoteRequest({
		code: createElectricityQuote.quoteRequest.code,
	});

	return quoteRequest as QuoteRequestQuery['quoteRequest'];
};

/**
 * Product param keys that can be filtered by True/False values.
 * "-" prefix performs a filter on False values.
 */
export const ProductFilterParams = [
	'is_all_denka',
	'-is_all_denka',
	'is_ev',
	'-is_ev',
	'is_fit',
	'-is_fit',
	'is_green',
	'-is_green',
	'is_solar',
	'-is_solar',
	'is_standard',
	'-is_standard',
	'is_fixed_fca',
	'-is_fixed_fca',
	'is_simple',
	'-is_simple',
] as const;

export type ProductFilterParamType = (typeof ProductFilterParams)[number][];

// By default, filter these products in the quote request
export const DefaultExcludeProductParams: ProductFilterParamType = [
	'-is_all_denka',
	'-is_simple',
	'-is_ev',
	'-is_solar',
];

// To quote All Electric Octopus Products exclusively
export const ProductParamsAllElectricOctopus: ProductFilterParamType = [
	'is_all_denka',
];

// To quote Green Octopus and Simple Octopus only
export const ProductParamsSimpleAndGreenOctopus: ProductFilterParamType = [
	'-is_all_denka',
	'-is_standard',
	'-is_ev',
	'-is_solar',
];

// To quote Green Octopus Products exclusively
export const ProductParamsGreenOctopus: ProductFilterParamType = [
	'is_green',
	'-is_ev',
	'-is_solar',
	'-is_all_denka',
];

// To quote EV Octopus Products exclusively
export const ProductParamsEvOctopus: ProductFilterParamType = ['is_ev']; // To quote Solar Octopus Products exclusively

// To quote Solar Octopus Products exclusively
export const ProductParamsSolarOctopus: ProductFilterParamType = ['is_solar'];

export const getQuoteProductParamsInput = ({
	isSimpleOctopusRegion,
}: {
	isSimpleOctopusRegion?: boolean;
}): Partial<{
	productParams: ProductFilterParamType;
}> => {
	const isAllElectricJourney =
		readSessionStorageOnce('user-journey') === JourneyVariantByPath.allElectric;
	const isFiTJourney =
		readSessionStorageOnce('user-journey') === JourneyVariantByPath.fit;
	const isEvJourney =
		readSessionStorageOnce('user-journey') === JourneyVariantByPath.ev;
	const isSolarJourney =
		readSessionStorageOnce('user-journey') === JourneyVariantByPath.solar;
	const isPriceComparisonChannel =
		readSessionStorageOnce('affiliateSalesChannel') === 'PRICE_COMPARISON';
	const isB2B2CChannel =
		readSessionStorageOnce('affiliateSalesChannel') === 'B2B2C';
	const isFieldSalesChannel =
		readSessionStorageOnce('affiliateSalesChannel') === 'FIELD_SALES';

	const affiliateOrganisationName = readSessionStorageOnce<string>(
		'affiliateOrganisationName'
	);

	const isValidAffiliateOrganizationOrElseNonAffiliate =
		!affiliateOrganisationName ||
		SHOULD_DISPLAY_SIMPLE_ORGANIZATION_NAMES.includes(
			affiliateOrganisationName
		) ||
		(!isPriceComparisonChannel && !isB2B2CChannel && !isFieldSalesChannel);

	const shouldDisplaySimpleOctopus =
		isSimpleOctopusRegion && isValidAffiliateOrganizationOrElseNonAffiliate;

	return isAllElectricJourney
		? { productParams: ProductParamsAllElectricOctopus }
		: isFiTJourney
			? { productParams: ProductParamsGreenOctopus }
			: isEvJourney
				? { productParams: ProductParamsEvOctopus }
				: isSolarJourney
					? { productParams: ProductParamsSolarOctopus }
					: shouldDisplaySimpleOctopus
						? { productParams: ProductParamsSimpleAndGreenOctopus }
						: { productParams: DefaultExcludeProductParams };
};

// @fixme - Earl
// used on the /shortcut/tariff step
// this should be the amount selected from the homepage quick-quote
export const DefaultUsageAmount = 300;

// Always the current month as an integer
export const DefaultUsageMonth = new Date().getMonth() + 1;
