import { NextRouter } from 'next/router';

import { COSGainType } from '@/components/pages/join/validationSchema';
import { JoinFormValues } from '@/data/join';
import apiClient from '@/services/api-client';
import graphqlClient from '@/services/graphql-client';
import {
	GenerationPointInput,
	InitiateMoveInInput,
	InitiateMoveInMutation,
	InitiateMoveOutAndMoveInInput,
	InitiateSwitchInInput,
	InitiateSwitchInMutation,
	PaymentTypeChoices,
} from '@/services/typed-graphql-sdk';
import { JourneyVariantByPath } from '@/utils/constants/marketing';
import { readSessionStorageOnce } from '@/utils/readSessionStorageOnce';

export const initiateMoveOutAndMoveInMutation = async ({
	values,
	router,
	analyticsCallback,
}: {
	analyticsCallback?: (params: object) => void;
	router: NextRouter;
	values: JoinFormValues;
}): Promise<void> => {
	const input = getInitiateMoveOutAndMoveInInput(values);
	const { initiateMoveOutAndMoveIn } =
		await graphqlClient.initiateMoveOutAndMoveIn({ input });
	switch (initiateMoveOutAndMoveIn.__typename) {
		case 'MoveOutAndMoveInInitiated': {
			const { accountNumber, token, hasUsablePassword } =
				initiateMoveOutAndMoveIn;
			if (analyticsCallback) {
				analyticsCallback({
					accountNumber,
					cosGainType: COSGainType.moveIn,
				});
			}

			router.push({
				pathname: '/join/set-user-password',
				query: {
					accountNumber,
					token,
					isMimo: 'true',
					hasUsablePassword,
					...router?.query,
				},
			});
			break;
		}
		case 'MoveInDateInvalid': {
			throw new Error(
				`Error: MoveInDateInvalid type in initiateMoveOutAndMoveIn mutation response. ${initiateMoveOutAndMoveIn.message}`
			);
		}
		case 'MoveOutDateInvalid': {
			throw new Error(
				`Error: MoveOutDateInvalid type in initiateMoveOutAndMoveIn mutation response. ${initiateMoveOutAndMoveIn.message}`
			);
		}
		// @ts-ignore @todo: remove once error type removed from BE response
		case 'CommonError': {
			throw new Error(
				`Error: CommonError type in initiateMoveOutAndMoveIn mutation response. ${initiateMoveOutAndMoveIn.message}`
			);
		}
	}
};

export const initiateMoveInMutation = async ({
	values,
	router,
	analyticsCallback,
}: {
	analyticsCallback?: (params: object) => void;
	router: NextRouter;
	values: JoinFormValues;
}): Promise<void> => {
	const input = getInitiateMoveInMutationInput(values);
	const { initiateMoveIn }: InitiateMoveInMutation = await apiClient.post(
		'/api/onboarding/initiate-move-in',
		{ input }
	);
	switch (initiateMoveIn.__typename) {
		case 'MoveInSuccess': {
			const { accountNumber, userId, token, hasUsablePassword } =
				initiateMoveIn;
			router.push({
				pathname: '/join/set-user-password',
				query: {
					accountNumber,
					userId,
					token,
					hasUsablePassword,
				},
			});
			if (analyticsCallback) {
				analyticsCallback({
					accountNumber: initiateMoveIn.accountNumber,
					cosGainType: COSGainType.moveIn,
				});
			}

			break;
		}
		case 'CommonError': {
			throw new Error(
				`Error: CommonError type in inititateMoveIn mutation response. ${initiateMoveIn.message}`
			);
		}
	}
};

export const initiateSwitchInMutation = async ({
	values,
	router,
	analyticsCallback,
}: {
	analyticsCallback?: (params: object) => void;
	router: NextRouter;
	values: JoinFormValues;
}): Promise<void> => {
	const input = getInitiateSwitchInInput(values);
	const { initiateSwitchIn }: InitiateSwitchInMutation = await apiClient.post(
		'/api/onboarding/initiate-switch-in',
		{ input }
	);

	if (analyticsCallback) {
		analyticsCallback({
			accountNumber: initiateSwitchIn?.accountNumber,
			cosGainType: COSGainType.switchIn,
		});
	}

	router.push({
		pathname: '/join/set-user-password',
		query: {
			accountNumber: initiateSwitchIn?.accountNumber,
			userId: initiateSwitchIn?.userId,
			token: initiateSwitchIn?.token,
			hasUsablePassword: initiateSwitchIn?.hasUsablePassword,
		},
	});
};

export const getInitiateMoveOutAndMoveInInput = (
	values: JoinFormValues
): InitiateMoveOutAndMoveInInput => {
	return {
		moveOut: {
			accountNumber: values.moveOutAccountId,
			moveOutDate: values.moveOutDate,
			propertyId: values.moveOutPropertyId,
		},
		syncMoveIn: {
			quoteCode: values.quote.code,
			productCode: values.productCode,
			moveInDate: values.moveInDate.replace(/\//g, '-'),
			property: {
				addressLine1: `${values.postalArea.prefecture}${values.postalArea.city}${values.postalArea.area}${values.addressLine1}`,
				addressLine2:
					values.propertyType === 'apartment'
						? `${values.buildingName} ${values.buildingNumber} ${values.roomNumber}`
						: '',
				postcode: values.postcode,
			},
			referralSchemeCode: values.referralSchemeCode,
			...getMoveOutAndMoveInBillingInputs(values),
		},
	};
};

export const getInitiateMoveInMutationInput = (
	values: JoinFormValues
): InitiateMoveInInput => {
	return {
		moveInDate: values.moveInDate.replace(/\//g, '-'),
		customer: {
			familyName: values.lastName,
			givenName: values.firstName,
			familyNameKana: values.lastNameKatakana,
			givenNameKana: values.firstNameKatakana,
			email: values.email,
			mobile: values.mobile,
			marketingCommunicationsOptIn: values.marketingCommunicationsOpt === 'in',
		},
		property: {
			addressLine1: `${values.postalArea.prefecture}${values.postalArea.city}${values.postalArea.area}${values.addressLine1}`,
			addressLine2:
				values.propertyType === 'apartment'
					? `${values.buildingName} ${values.buildingNumber} ${values.roomNumber}`
					: '',
			postcode: values.postcode,
		},
		...getPaymentInputs(values),
		...getCommonInputs(values),
	};
};

export const getInitiateSwitchInInput = (
	values: JoinFormValues
): InitiateSwitchInInput => {
	return {
		customer: {
			familyName: values.lastName,
			givenName: values.firstName,
			familyNameKana: values.lastNameKatakana,
			givenNameKana: values.firstNameKatakana,
			email: values.email,
			mobile: values.mobile,
			marketingCommunicationsOptIn: values.marketingCommunicationsOpt === 'in',
			contractFamilyName: values.contractFamilyName,
			contractGivenName: values.contractGivenName,
			contractFamilyNameKana: values.contractFamilyNameKana,
			contractGivenNameKana: values.contractGivenNameKana,
			contractNumber: values.currentSupplierContractNumber,
		},
		property: {
			addressLine1: `${values.postalArea.prefecture}${values.postalArea.city}${values.postalArea.area}${values.addressLine1}`,
			addressLine2:
				values.propertyType === 'apartment'
					? `${values.buildingName} ${values.buildingNumber} ${values.roomNumber}`
					: '',
			postcode: values.postcode,
			...(values.SPIN && {
				supplyPointId: values.SPIN?.replace(/-/g, ''),
			}),
			currentSupplierCode: values.currentRetailer,
		},
		...getPaymentInputs(values),
		...getCommonInputs(values),
	};
};

const getPaymentInputs = (values: JoinFormValues) => {
	const [paymentTokenKey, paymentToken] =
		values.paymentType === PaymentTypeChoices.CreditCard
			? ['creditCardToken', values.creditCardToken]
			: values.paymentType === PaymentTypeChoices.BankAccount
				? ['bankAccountToken', values.bankAccountToken]
				: [];
	return {
		payment: {
			paymentType: values.paymentType || PaymentTypeChoices.Konbini,
			...(paymentTokenKey && { [paymentTokenKey]: paymentToken }),
			...(values.paymentType === PaymentTypeChoices.BankAccount
				? {
						accountNumber: values.bankAccountNumber,
						accountHolder: values.bankAccountHolderName,
					}
				: {}),
		},
		billing: {
			billingAddressLine1: `${values.billingPostalArea.prefecture}${values.billingPostalArea.city}${values.billingPostalArea.area}${values.billingAddressLine1}`,
			billingAddressLine2:
				values.billingPropertyType === 'apartment'
					? `${values.billingBuildingName}${values.billingBuildingNumber}${values.billingRoomNumber}`
					: '',
			billingPostcode: values.billingPostcode,
		},
	};
};

const getMoveOutAndMoveInBillingInputs = (values: JoinFormValues) => {
	return {
		billing: {
			billingAddressLine1: `${values.billingPostalArea.prefecture}${values.billingPostalArea.city}${values.billingPostalArea.area}${values.billingAddressLine1}`,
			billingAddressLine2:
				values.billingPropertyType === 'apartment'
					? `${values.billingBuildingName}${values.billingBuildingNumber}${values.billingRoomNumber}`
					: '',
			billingPostcode: values.billingPostcode,
		},
	};
};

const getCommonInputs = (values: JoinFormValues) => {
	return {
		quoteCode: values.quote.code,
		productCode: values.productCode,
		...(values.affiliateLinkSubdomain
			? { affiliateLinkSubdomain: values.affiliateLinkSubdomain }
			: {}),
		...(values.referralCode ? { referralCode: values.referralCode } : {}),
		...(values.referralSchemeCode
			? { referralSchemeCode: values.referralSchemeCode }
			: {}),
		...getRewardCodeInput(),
		...getGenerationDataInput(values),
	};
};
/**
 * Determine the reward or affiliate code input for the InitiateSwitchInMutation.
 *
 * Return one or many of the following:
 * - An affiliate code and (optionally) audio recording id.
 * - A valid referral code. (friend referral)
 * - A referral scheme code. (any ReferralScheme code)
 */

export const getRewardCodeInput = (): Pick<
	InitiateSwitchInInput,
	| 'affiliateLinkSubdomain'
	| 'audioRecordingId'
	| 'referralCode'
	| 'referralSchemeCode'
> => {
	const referralCode = readSessionStorageOnce<string>('referralCode');
	const referralSchemeCode =
		readSessionStorageOnce<string>('referralSchemeCode');
	const affiliateCode = readSessionStorageOnce<string>('affiliateCode');
	const audioRecordingId = readSessionStorageOnce<string>('audioRecordingId');

	/*
	 * referralCode and referralSchemeCode can not be both included because it
	 * will apply both rewards to the account, which isn't allowed.
	 * In case both are present, use referralCode.
	 */
	const resolveReferralRewardConflict = (
		referralCode: string | null,
		referralSchemeCode: string | null
	) => {
		if (referralSchemeCode && !referralCode) {
			return { referralSchemeCode };
		} else if (referralCode) {
			return { referralCode };
		} else {
			return {};
		}
	};

	return {
		...(affiliateCode && !referralCode
			? {
					affiliateLinkSubdomain: affiliateCode,
					...(audioRecordingId ? { audioRecordingId } : {}),
				}
			: {}),
		...resolveReferralRewardConflict(referralCode, referralSchemeCode),
	};
};

export const getGenerationDataInput = (
	updatedValues: JoinFormValues
): { generationPoint: GenerationPointInput } | Record<string, unknown> => {
	const isFiTJourney =
		readSessionStorageOnce('user-journey') === JourneyVariantByPath.fit;

	return isFiTJourney
		? {
				generationPoint: {
					customer: {
						contractFamilyName: updatedValues.lastName,
						contractFamilyNameKana: updatedValues.lastNameKatakana,
						contractGivenName: updatedValues.firstName,
						contractGivenNameKana: updatedValues.firstNameKatakana,
						contractNumber: updatedValues.currentGenerationContractNumber,
						email: updatedValues.email,
						familyName: updatedValues.lastName,
						familyNameKana: updatedValues.lastNameKatakana,
						givenName: updatedValues.firstName,
						givenNameKana: updatedValues.firstNameKatakana,
						marketingCommunicationsOptIn:
							updatedValues.marketingCommunicationsOpt === 'in',
						mobile: updatedValues.mobile,
					},
					generationPointId: updatedValues.GPIN.replace(/-/g, ''),
					currentSupplierCode: updatedValues.currentGenerationPurchasor,
					productCode: updatedValues.quotedGenerationProduct.product.code,
				} as GenerationPointInput,
			}
		: {};
};
